import React, { lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, Outlet, redirect } from 'react-router-dom'
import { RoleId, RouteId } from 'common/enums'
import AuthLayout from 'components/auth-layout'
import Layout from 'components/layout'
import QuerySuspenseBoundary from 'components/query-suspense-boundary'
import App from './App'
import './index.css'

import {
  RootAuthPages,
  LoginPage,
  Login2FAPage,
  LoginOtpPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  UnlockAccountPage,
} from 'pages/auth'

import RootProtectedPages from 'pages/protected/root'
import IndexRedirect from 'pages/protected/index-redirect'
import RestrictedPage from 'pages/protected/restricted'
import ErrorPage from 'pages/protected/error'

const DashboardPage = lazy(() => import('./pages/protected/dashboard'))
const UserProfilePage = lazy(() => import('./pages/protected/user-profile'))
const UsersListPage = lazy(() => import('./pages/protected/users'))
const OperatorsListPage = lazy(() => import('./pages/protected/operators'))
const AlarmsListPage = lazy(() => import('./pages/protected/alarms'))
const DevicesPage = lazy(() => import('./pages/protected/devices'))
const DevicesListTab = lazy(() => import('./pages/protected/devices/devices'))
const DeviceBulkActionsListTab = lazy(() => import('./pages/protected/devices/device-bulk-actions'))
const DeviceDetailsPage = lazy(() => import('./pages/protected/device-details'))
const DeviceConfigurationsTab = lazy(() => import('./pages/protected/device-details/device-configurations'))
const DeviceUserSettingsTab = lazy(() => import('./pages/protected/device-details/device-user-settings'))
const DeviceRemoteAssistanceTab = lazy(() => import('./pages/protected/device-details/device-remote-assistance'))
const DeviceStatusTab = lazy(() => import('./pages/protected/device-details/device-status'))
const DeviceAlarmsListTab = lazy(() => import('./pages/protected/device-details/device-alarms'))
const DeviceProfilesListPage = lazy(() => import('./pages/protected/device-profiles'))
const DeviceProfileDetailsPage = lazy(() => import('./pages/protected/device-profile-details'))
const AppsListTab = lazy(() => import('./pages/protected/device-profile-details/apps'))
const AppPermissionsListTab = lazy(() => import('./pages/protected/device-profile-details/app-permissions'))
const AppConfigurationsListTab = lazy(() => import('./pages/protected/device-profile-details/app-configurations'))
const DeviceProfileROMsTab = lazy(() => import('./pages/protected/device-profile-details/device-profile-roms'))
const DeviceProfileConfigurationsTab = lazy(
  () => import('./pages/protected/device-profile-details/device-profile-configurations'),
)
const ROMsListPage = lazy(() => import('./pages/protected/roms'))
const RemoteAssistancePage = lazy(() => import('./pages/protected/remote-assistance'))
const RemoteAssistanceDevicesListTab = lazy(
  () => import('./pages/protected/remote-assistance/remote-assistance-devices'),
)
const RemoteAssistanceSessionsListTab = lazy(
  () => import('./pages/protected/remote-assistance/remote-assistance-sessions'),
)
const RemoteAssistanceSessionDetailsPage = lazy(() => import('./pages/protected/remote-assistance-session-details'))
const RemoteAssistanceSessionActionsHistoryTab = lazy(
  () => import('./pages/protected/remote-assistance-session-details/remote-assistance-session-actions-history'),
)
const RemoteAssistanceSessionPage = lazy(() => import('./pages/protected/remote-assistance-session'))
const ConfigurationsAdminPage = lazy(() => import('./pages/protected/configurations-admin'))
const ConfigurationsOperatorPage = lazy(() => import('./pages/protected/configurations-operator'))

const PageQuerySuspenseBoundary = () => (
  <QuerySuspenseBoundary>
    <Outlet />
  </QuerySuspenseBoundary>
)

const {
  SYS_OWNER,
  SYS_ADMIN,
  OPR_OWNER,
  OPR_ADMIN,
  OPR_EDITOR,
  OPR_VIEWER,
  OPR_SUPPORT_LINE_1,
  OPR_SUPPORT_LINE_2,
  //
} = RoleId

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RootAuthPages />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              {
                path: '/login',
                element: <LoginPage />,
              },
              {
                path: '/auth/2fa',
                element: <Login2FAPage />,
              },
              {
                path: '/auth/password/forgot',
                element: <ForgotPasswordPage />,
              },
              {
                path: '/auth/password/reset',
                element: <ResetPasswordPage />,
              },
              {
                path: '/auth/unlock',
                element: <UnlockAccountPage />,
              },
            ],
          },
          {
            path: '/auth/otp',
            element: <LoginOtpPage />,
          },
        ],
      },
      {
        element: <RootProtectedPages />,
        children: [
          {
            element: <Layout />,
            children: [
              {
                element: <PageQuerySuspenseBoundary />,
                children: [
                  {
                    index: true,
                    element: <IndexRedirect />,
                  },
                  {
                    element: (
                      <RestrictedPage
                        allowedRoles={[SYS_OWNER, SYS_ADMIN, OPR_OWNER, OPR_ADMIN, OPR_EDITOR, OPR_VIEWER]}
                      />
                    ),
                    children: [
                      {
                        path: '/dashboard',
                        handle: {
                          crumb: () => 'Dashboard',
                        },
                        element: <DashboardPage />,
                      },
                    ],
                  },
                  {
                    path: '/user-profile',
                    handle: {
                      crumb: () => 'User Profile',
                    },
                    element: <UserProfilePage />,
                  },
                  {
                    element: <RestrictedPage route={RouteId.USER_LIST} />,
                    children: [
                      {
                        path: '/users',
                        handle: {
                          crumb: () => 'Users',
                        },
                        element: <UsersListPage />,
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.OPERATOR_LIST} />,
                    children: [
                      {
                        path: '/operators',
                        handle: {
                          crumb: () => 'Operators',
                        },
                        element: <OperatorsListPage />,
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.ALARM_LIST} />,
                    children: [
                      {
                        path: '/alarms',
                        handle: {
                          crumb: () => 'Alarms',
                        },
                        element: <AlarmsListPage />,
                      },
                    ],
                  },
                  {
                    element: (
                      <RestrictedPage
                        route={RouteId.DEVICE_LIST}
                        forbiddenRoles={[SYS_OWNER, OPR_SUPPORT_LINE_1, OPR_SUPPORT_LINE_2]}
                      />
                    ),
                    children: [
                      {
                        path: '/devices',
                        handle: {
                          crumb: () => 'Devices',
                        },
                        element: <DevicesPage />,
                        children: [
                          {
                            element: <PageQuerySuspenseBoundary />,
                            children: [
                              { index: true, element: <DevicesListTab /> },
                              {
                                element: <RestrictedPage route={RouteId.BULK_ACTION_LIST} />,
                                children: [{ path: 'bulk-actions', element: <DeviceBulkActionsListTab /> }],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    element: (
                      <RestrictedPage
                        route={RouteId.DEVICE_READ}
                        forbiddenRoles={[SYS_OWNER, OPR_SUPPORT_LINE_1, OPR_SUPPORT_LINE_2]}
                      />
                    ),
                    children: [
                      {
                        path: '/devices/:pageId',
                        handle: {
                          crumb: () => 'Devices',
                        },
                        element: <DeviceDetailsPage />,
                        children: [
                          {
                            element: <PageQuerySuspenseBoundary />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect('configurations'),
                              },
                              { path: 'configurations', element: <DeviceConfigurationsTab /> },
                              { path: 'user-settings', element: <DeviceUserSettingsTab /> },
                              { path: 'remote-assistance', element: <DeviceRemoteAssistanceTab /> },
                              { path: 'status', element: <DeviceStatusTab /> },
                              { path: 'alarms', element: <DeviceAlarmsListTab /> },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.DEVICE_PROFILE_LIST} forbiddenRoles={[SYS_OWNER]} />,
                    children: [
                      {
                        path: '/device-profiles',
                        handle: {
                          crumb: () => 'Device Profiles',
                        },
                        element: <DeviceProfilesListPage />,
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.DEVICE_PROFILE_READ} forbiddenRoles={[SYS_OWNER]} />,
                    children: [
                      {
                        path: '/device-profiles/:pageId',
                        handle: {
                          crumb: () => 'Device Profiles',
                        },
                        element: <DeviceProfileDetailsPage />,
                        children: [
                          {
                            element: <PageQuerySuspenseBoundary />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect('apps'),
                              },
                              { path: 'apps', element: <AppsListTab /> },
                              { path: 'app-permissions', element: <AppPermissionsListTab /> },
                              { path: 'app-configurations', element: <AppConfigurationsListTab /> },
                              { path: 'roms', element: <DeviceProfileROMsTab /> },
                              { path: 'configurations', element: <DeviceProfileConfigurationsTab /> },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.ROM_LIST} forbiddenRoles={[SYS_OWNER]} />,
                    children: [
                      {
                        path: '/roms',
                        handle: {
                          crumb: () => 'ROMs',
                        },
                        element: <ROMsListPage />,
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.SESSION_LIST} forbiddenRoles={[SYS_OWNER]} />,
                    children: [
                      {
                        path: '/remote-assistance',
                        handle: {
                          crumb: () => 'Remote Assistance',
                        },
                        element: <RemoteAssistancePage />,
                        children: [
                          {
                            element: <PageQuerySuspenseBoundary />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect('devices'),
                              },
                              { path: 'devices', element: <RemoteAssistanceDevicesListTab /> },
                              { path: 'sessions', element: <RemoteAssistanceSessionsListTab /> },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.SESSION_READ} forbiddenRoles={[SYS_OWNER]} />,
                    children: [
                      {
                        path: '/remote-assistance/:pageId',
                        handle: {
                          crumb: () => 'Remote Assistance',
                        },
                        element: <RemoteAssistanceSessionDetailsPage />,
                        children: [
                          {
                            element: <PageQuerySuspenseBoundary />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect('actions-history'),
                              },
                              { path: 'actions-history', element: <RemoteAssistanceSessionActionsHistoryTab /> },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage route={RouteId.SESSION_READ} forbiddenRoles={[SYS_OWNER, OPR_VIEWER]} />,
                    children: [
                      {
                        path: '/remote-assistance/:pageId/session',
                        handle: {
                          crumb: () => 'Remote Session',
                        },
                        element: <RemoteAssistanceSessionPage />,
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage allowedRoles={[SYS_OWNER, SYS_ADMIN]} />,
                    children: [
                      {
                        path: '/admin-configurations',
                        handle: {
                          crumb: () => 'Configurations',
                        },
                        element: <ConfigurationsAdminPage />,
                      },
                    ],
                  },
                  {
                    element: <RestrictedPage allowedRoles={[OPR_OWNER, OPR_ADMIN]} />,
                    children: [
                      {
                        path: '/configurations',
                        handle: {
                          crumb: () => 'Configurations',
                        },
                        element: <ConfigurationsOperatorPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
