import { CORE_API_DOMAIN, AUTH_BASE_URL } from '../../API_routes'
import {
  IResponse,
  ISelectOption,
  IAuthStatistics,
  ICoreStatistics,
  IDeviceProfile,
  IRom,
  IBoxStatistics,
  IOperatorDevicesActivity,
} from '../interfaces'
import axios from '../axiosInstance'

export const getRoles = () => axios.get<IResponse<ISelectOption[]>>(`${AUTH_BASE_URL}/list/roles`)

export const getDashboardAuthStatistics = ({ mode }: { mode: number } = { mode: 1 }) =>
  axios.get<IResponse<IAuthStatistics>>(`${AUTH_BASE_URL}/dashboard/statistics`, {
    params: { mode },
  })

export const getDashboardCoreStatistics = ({ mode }: { mode: number } = { mode: 1 }) =>
  axios.get<IResponse<ICoreStatistics>>(`${CORE_API_DOMAIN}/dashboard/statistics`, {
    params: { mode },
  })

export const getDashboardDeviceProfiles = ({ mode }: { mode: number } = { mode: 1 }) =>
  axios.get<IResponse<IDeviceProfile[]>>(`${CORE_API_DOMAIN}/dashboard/deviceProfiles`, {
    params: { mode },
  })

export const getDashboardRoms = ({ mode }: { mode: number } = { mode: 1 }) =>
  axios.get<IResponse<IRom[]>>(`${CORE_API_DOMAIN}/dashboard/rom`, { params: { mode } })

export const getDashboardBoxStatistics = ({ mode }: { mode: number } = { mode: 1 }) =>
  axios.get<IResponse<IBoxStatistics>>(`${CORE_API_DOMAIN}/dashboard/box/statistics`, { params: { mode } })

export const getOperatorDevicesActivity = ({
  profileId,
  timestamp,
  mode = 1,
}: {
  profileId?: string
  timestamp?: number
  mode?: number
}) =>
  axios.get<IResponse<IOperatorDevicesActivity>>(`${CORE_API_DOMAIN}/operator-devices/activity`, {
    params: {
      'filter[profile_id]': profileId,
      'filter[timestamp]': timestamp,
      mode,
    },
  })
